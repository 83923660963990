/* With opacity */
.theme-steel-blue {
    --color-primary-opacity: 100, 184, 255; /*#75deff; rgb(117,222,255)*/ /*[o] TODO */
    --color-secondary-opacity: 144, 189, 227; /*#90bde3; rgb(144,189,227)*/ /*[o] TODO */
    --color-accent-opacity: 147, 194, 232; /*#93c2e8;  */ /*[x] TODO */

    --color-action-opacity: 144, 189, 227; /*#90bde3; rgb(144,189,227) */ /*[o] TODO */
    --color-action-hover-opacity: 99, 131, 158; /*#63839e; rgb(99,131,158)  */ /*[o] TODO */
    --color-focus-opacity: 99, 131, 158; /*#63839e; rgb(99,131,158)  */ /*[o] TODO */
    --color-disabled-opacity: 153, 153, 153; /*#939393; rgb(153,153,153) */ /*[o] TODO */

    --color-border-opacity: 99, 131, 158; /*#63839e; rgb(99,131,158);*/ /*[o] TODO */
    --color-border-accent-opacity: 117, 222, 255; /*#75deff; rgb(117,222,255);*/ /*[o] TODO */

    --color-text-opacity: 217, 217, 217; /*#d9d9d9; rgb(217,217,217)*/ /*[o] TODO */
    --color-text-accent-opacity: 144, 189, 227; /*#90bde3; rgb(144,189,227)*/ /*[o] TODO */

    --color-theme-white-opacity: 255, 255, 255; /*#ffffff; rgb(255,255,255)*/ /*[o] TODO */
    --color-theme-black-opacity: 18, 18, 18; /*#0d0d0d; rgb(13,13,13);*/ /*[o] TODO */

    --color-theme-opacity: 1;
}

.theme-steel-blue {
    --color-primary: rgb(
        var(--color-primary-opacity)
    ); /*#75deff; */ /*[o] TODO  */
    --color-secondary: rgb(147, 194, 232); /*#93c2e8; */ /*[o] TODO */
    --color-accent: rgb(
        var(--color-accent-opacity)
    ); /*#93c2e8; */ /*[x] TODO */

    --color-action: rgb(147, 194, 232); /*#93c2e8; */ /*[o] TODO */
    --color-action-hover: rgb(
        var(--color-action-hover-opacity)
    ); /*#63839e; */ /*[o] TODO */
    --color-focus: rgb(var(--color-focus-opacity)); /*#63839e; */ /*[o] TODO */
    --color-disabled: rgb(
        var(--color-disabled-opacity)
    ); /*#939393; */ /*[o] TODO */

    --color-border: rgb(
        var(--color-border-opacity)
    ); /*#63839e; */ /*[x] TODO */
    --color-border-accent: rgb(118, 149, 178); /*#7695b2; */ /*[x] TODO */

    --color-text: rgb(var(--color-text-opacity)); /*#d9d9d9; */ /*[o] TODO */
    --color-text-accent: rgb(147, 194, 232); /*#93c2e8; */ /*[o] TODO */

    --color-theme-white: rgb(
        var(--color-theme-white-opacity)
    ); /*#ffffff; */ /*[o] TODO */
    --color-theme-black: rgb(
        var(--color-theme-black-opacity)
    ); /*#0d0d0d; */ /*[o] TODO */

    --color-dark-black: #0d0d0d;
    --color-light-black: #252525;
}
