:root {
    --game-font: 'Chakra Petch';

    --roulette-zero-green: #14ae5c;
    --roulette-border-color: #7695b2;
    --roulette-white-color: #d9d9d9;
    --roulette-gray-color: #ababab;
    --roulette-red: #f24822;
    --roulette-hover: #75deff;
    --roulette-blue: #75deff;
    --roulette-hover-black: #21406b;
    --roulette-hover-red: #494869;
    --roulette-opaque-gradient: linear-gradient(
            0deg,
            rgba(102, 102, 102, 0.19),
            rgba(102, 102, 102, 0.19)
        ),
        radial-gradient(
            112.5% 112.5% at 50% 15.28%,
            rgba(12, 12, 12, 0.85) 24.5%,
            rgba(0, 0, 0, 0.374) 100%
        );
    --roulette-opaque-background: rgba(20, 20, 20, 0.75);

    --roulette-border-radius: 1.4rem;
    --roulette-hover-border-thickness: 0.25rem;

    --roulette-cell-width: 5.6rem;
    --roulette-cell-height: 7rem;
    --roulette-edge-thickness: 2rem;
    --roulette-bottom-row-height: 4.5rem;

    --roulette-hover-duration: 10ms;

    --roulette-resulted-once: #1e5b84;
    --roulette-resulted-twice: #2c2c5e;
    --roulette-resulted-thrice: #f24822;

    --primary-game-ui-color: #75deff;
    --secondary-game-ui-color: #ffffff;

    --primary-fill-color: #ababab;
    --primary-border-color: #63839e;
    --roulette-balance-color: #75deff;
    --side-button: linear-gradient(
        180deg,
        rgba(35, 35, 35, 0.3) 31%,
        rgba(137, 137, 137, 0.3) 180%
    );
    --side-button-background: linear-gradient(
        180deg,
        rgba(35, 35, 35, 0.3) 31%,
        rgba(137, 137, 137, 0.3) 180%
    );
    --stroke-side: rgba(85, 85, 85, 1);

    --opaque-background: rgba(0, 0, 0, 0.4);
}
