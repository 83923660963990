:root {
    /* *************************************************** */
    /* COlORS 
    /* ***************************************************
    /* CSS does not hoist
    /* [x] TODO means its a real color from the design
    /*  
    /* There are two variabales declared to allow for the use of opacity
    /* ```
    /* color: var(--color-primary);
    /* color:  rgba(var(--color-primary-opacity), 0.8);
    /* ```
    /* *************************************************** */
    --color-pink-100: rgb(255, 192, 203); /*#ffc0cb*/ /*[] TODO*/
    --color-pink-80: rgb(255, 182, 193); /*#ffb6c1*/ /*[] TODO*/
    --color-pink-60: rgb(255, 174, 185); /*#ffaeab*/ /*[] TODO*/
    --color-pink-40: rgb(255, 160, 176); /*#ffa0b0*/ /*[] TODO*/
    --color-pink-20: rgb(255, 225, 230); /*#ffe1e6*/ /*[] TODO*/

    --color-steel-blue-100: rgb(117, 222, 255); /*#75deff;*/ /*[x] TODO*/
    --color-steel-blue-80: rgb(147, 194, 232); /*#93c2e8 */ /*[x] TODO*/
    --color-steel-blue-60: rgb(144, 189, 227); /*#90bde3 */ /*[x] TODO*/
    --color-steel-blue-40: rgb(99, 131, 158); /*#63839e */ /*[x] TODO*/
    --color-steel-blue-20: rgb(171, 171, 171); /*#ababab */ /*[x] TODO*/

    --color-gold-100: rgb(255, 221, 170); /* #ffddaa;  */ /*[x] TODO*/
    --color-gold-80: rgb(255, 210, 158); /* #ffd29e;  */ /*[x] TODO*/
    --color-gold-60: rgb(255, 209, 123); /* #ffd17b;  */ /*[x] TODO*/
    --color-gold-40: rgb(205, 177, 135); /* #cdb187;  */ /*[x] TODO*/
    --color-gold-20: rgb(129, 107, 81); /* #816b51;  */ /*[x] TODO*/

    --color-rag-green: rgb(0, 255, 102); /*#00ff66;*/ /*[x] TODO*/
    --color-rag-amber: rgb(255, 166, 0); /*#ffa600;*/ /*[x] TODO*/
    --color-rag-red: rgb(255, 0, 0); /* #ff0000;*/ /*[x] TODO*/

    /* Grey */
    --color-grey-100: rgb(153, 153, 153); /*#999999;*/ /*[o] TODO */
    --color-grey-80: rgb(144, 144, 144); /*#909090;*/ /*[x] TODO */
    --color-grey-60: rgb(147, 147, 147); /*#939393;*/ /*[x] TODO */
    --color-grey-40: rgb(218, 218, 218); /*#dadada;*/ /*[o] TODO ??? */
    --color-grey-20: rgb(218, 218, 218); /*#dadada;*/ /*[x] TODO */

    /* Dark Grey */
    --color-dark-grey-100: rgb(51, 51, 51); /* "#333333;" */ /*[x] TODO*/
    --color-dark-grey-80: rgb(67, 67, 67); /* "#434343;" */ /*[x] TODO*/
    --color-dark-grey-60: rgb(76, 76, 76); /* "#4c4c4c;" */ /*[x] TODO*/
    --color-dark-grey-40: rgb(81, 81, 81); /* "#515151;" */ /*[x] TODO*/
    --color-dark-grey-20: rgb(102, 102, 102); /* "#666666;" */ /*[x] TODO*/

    /* Black */
    --color-black-100: rgb(0, 0, 0); /* "#000000;" */ /*[x] TODO*/
    --color-black-80: rgb(13, 13, 13); /* "#0d0d0d;" */ /*[x] TODO*/
    --color-black-60: rgb(21, 21, 21); /* "#151515;" */ /*[x] TODO*/
    --color-black-40: rgb(32, 32, 32); /* "#202020;" */ /*[x] TODO*/
    --color-black-20: rgb(44, 44, 44); /* "#2c2c2c;" */ /*[x] TODO*/

    /* White */
    --color-white-100: rgb(255, 255, 255); /* "#ffffff;" */ /*[x] TODO  */
    --color-white-80: rgb(248, 248, 255); /* "#f8f8ff;" */ /*[x] TODO  */
    --color-white-60: rgb(153, 153, 153); /* "#999999;" */ /*[o] TODO  */
    --color-white-40: rgb(102, 102, 102); /* "#666666;" */ /*[o] TODO  */
    --color-white-20: rgb(51, 51, 51); /* "#333333;" */ /*[o] TODO  */

    /* *************************************************** */
    /* CUSTOM COLORS
    /* *************************************************** */
    --color-animo-black: rgb(13, 13, 13); /* "#0d0d0d" */ /*[x] TODO  */
    --color-animo-gun-metal: rgb(21, 21, 21); /* "#151515" */ /*[x] TODO  */
}

/* With Opacity */
:root {
    --color-pink-100-opacity: 255, 192, 203; /*#ffc0cb rgb(255, 192, 203) */ /*[] TODO*/
    --color-pink-80-opacity: 255, 182, 193; /*#ffb6c1 rgb(255, 182, 193) */ /*[] TODO*/
    --color-pink-60-opacity: 255, 174, 185; /*#ffaeab rgb(255, 174, 185) */ /*[] TODO*/
    --color-pink-40-opacity: 255, 160, 176; /*#ffa0b0 rgb(255, 160, 176) */ /*[] TODO*/
    --color-pink-20-opacity: 255, 225, 230; /*#ffe1e6 rgb(255, 225, 230) */ /*[] TODO*/

    --color-steel-blue-opacity-100: 117, 222, 255; /*#75deff rgb(117,222,255); */ /*[x] TODO*/
    --color-steel-blue-opacity-80: 147, 194, 232; /*# rgb(147,194,232); */ /*[x] TODO*/
    --color-steel-blue-opacity-60: 144, 189, 227; /*#90bde3 rgb(144,189,227); */ /*[x] TODO*/
    --color-steel-blue-opacity-40: 99, 131, 158; /*#63839e rgb(99,131,158);  */ /*[x] TODO*/
    --color-steel-blue-opacity-20: 171, 171, 171; /*#ababab rgb(171,171,171); */ /*[x] TODO*/

    --color-gold-100-opacity: 255, 221, 170; /*#ffddaa;  rgb(255,221,170);  */ /*[x] TODO*/
    --color-gold-80-opacity: 255, 209, 123; /*#ffd29e;  rgb(255,209,123);   */ /*[x] TODO*/
    --color-gold-60-opacity: 255, 209, 123; /*#ffd17b;  rgb(255,209,123); */ /*[x] TODO*/
    --color-gold-40-opacity: 205, 177, 135; /*#cdb187;  rgb(205, 177,135);  */ /*[x] TODO*/
    --color-gold-20-opacity: 129, 107, 81; /*#816b51;  rgb(129,107,81);  */ /*[x] TODO*/

    --color-rag-green-opacity: 0, 255, 102; /*#00ff66; rgb(0,255,102);  */ /*[x] TODO*/
    --color-rag-amber-opacity: 255, 166, 0; /*#ffa600; rgb(255,166,0); */ /*[x] TODO*/
    --color-rag-red-opacity: 255, 0, 0; /*#ff0000; rgb(255,0,0);   */ /*[x] TODO*/

    /* Grey */
    --color-grey-100-opacity: 153, 153, 153; /*#999999; rgb(153,153,153); */ /*[o] TODO */
    --color-grey-80-opacity: 144, 144, 144; /*#909090; rgb(144,144,144); */ /*[x] TODO */
    --color-grey-60-opacity: 147, 147, 147; /*#939393; rgb(147,147,147); */ /*[x] TODO */
    --color-grey-40-opacity: 218, 218, 218; /*#dadada; rgb(218,218,218); */ /*[o] TODO */
    --color-grey-20-opacity: 218, 218, 218; /*#dadada; rgb(218,218,218); */ /*[x] TODO */

    /* Dark Grey */
    --color-dark-grey-100-opacity: 51, 51, 51; /* "#333333;" */ /*[x] TODO*/
    --color-dark-grey-80-opacity: 67, 67, 67; /* "#434343;" */ /*[x] TODO*/
    --color-dark-grey-60-opacity: 76, 76, 76; /* "#4c4c4c;" */ /*[x] TODO*/
    --color-dark-grey-40-opacity: 81, 81, 81; /* "#515151;" */ /*[x] TODO*/
    --color-dark-grey-20-opacity: 102, 102, 102; /* "#666666;" */ /*[x] TODO*/

    /* Black */
    --color-black-100-opacity: 0, 0, 0; /* #000000;  rgb(0,0,0);       */ /*[x] TODO*/
    --color-black-80-opacity: 13, 13, 13; /* "#0d0d0d; rgb(13,13,13);    */ /*[x] TODO */
    --color-black-60-opacity: 21, 21, 21; /* "#151515; rgb(21,21,21);    */ /*[x] TODO*/
    --color-black-40-opacity: 32, 32, 32; /* "#202020; rgb(32,32,32); */ /*[o] TODO*/
    --color-black-20-opacity: 44, 44, 44; /* " #2c2c2c; rgb(44,44,44); */ /*[0] TODO*/

    /* White */
    --color-white-100-opacity: 255, 255, 255; /* #ffffff; rgb(255,255,255)  */ /*[x] TODO */
    --color-white-80-opacity: 248, 248, 255; /* #f8f8f8; rgb(248,248,248)  */ /*[x] TODO*/
    --color-white-60-opacity: 153, 153, 153; /* #999999; rgb(153,153,153)  */ /*[o] TODO*/
    --color-white-40-opacity: 102, 102, 102; /* #666666; rgb(102,102,102)  */ /*[o] TODO*/
    --color-white-20-opacity: 51, 51, 51; /* #333333; rgb(51,51,51)     */ /*[o] TODO*/

    /* *************************************************** */
    /* CUSTOM COLORS
  /* *************************************************** */
    --color-animo-black-opacity: 13, 13, 13; /* #0d0d0d; rgb(13,13,13) */ /*[x] TODO */
    --color-animo-gun-metal-opacity: 21, 21, 21; /* #151515; rgb(21,21,21) */ /*[x] TODO */
}
