.theme-pink {
    --color-primary: #ffc0cb;
    --color-secondary: #ffb6c1;
    --color-accent: #ffe1e6;

    --color-action: #ffe1e6;
    --color-action-hover: #ffe1e6;
    --color-focus: #ffe1e6;
    --color-disabled: rgb(153, 153, 153); /*#939393; */ /*[o] TODO */

    --color-border: #ffe1e6;
    --color-border-accent: #ffa0b0;

    --color-text: rgb(255, 255, 255); /*#ffffff; */ /*[o] TODO */
    --color-text-accent: #ffa0b0; /*[o] TODO */

    --color-theme-white: rgb(255, 255, 255); /*#ffffff; */ /*[o] TODO */
    --color-theme-black: rgb(13, 13, 13); /*#0d0d0d; */ /*[o] TODO */

    --color-dark-black: #0d0d0d;
}

/* With Opacity */
.theme-pink {
    --color-primary-opacity: 255, 192, 203; /* #ffc0cb; rgb(255, 192, 203) */ /*[o] TODO */
    --color-secondary-opacity: 255, 182, 193; /* #ffb6c1; rgb(255, 182, 193) */ /*[o] TODO */
    --color-accent-opacity: 255, 225, 230; /* #ffe1e6; rgb(255, 225, 230) */ /*[o] TODO */

    --color-action-opacity: 255, 225, 230; /* #ffe1e6; rgb(255, 225, 230) */ /*[o] TODO */
    --color-action-hover-opacity: 255, 225, 230; /* #ffe1e6; rgb(255, 225, 230) */ /*[o] TODO */
    --color-focus-opacity: 255, 225, 230; /* #ffe1e6; rgb(255, 225, 230) */ /*[o] TODO */
    --color-disabled-opacity: 153, 153, 153; /*#939393; rgb(153, 153, 153) */ /*[o] TODO */

    --color-border-opacity: 255, 225, 230; /* #ffe1e6; rgb(255, 225, 230) */ /*[o] TODO */
    --color-border-accent-opacity: 255, 160, 176; /*#ffa0b0; rgb(255, 160, 176) */ /*[o] TODO */

    --color-text-opacity: 255, 255, 255; /*#ffffff; rgb(255, 255, 255) */ /*[o] TODO */
    --color-text-accent-opacity: 255, 160, 176; /*#ffa0b0; rgb(255, 160, 176) */ /*[o] TODO */

    --color-theme-white-opacity: 255, 255, 255; /*#ffffff; rgb(255, 255, 255) */ /*[o] TODO */
    --color-theme-black-opacity: 13, 13, 13; /*#0d0d0d; rgb(13, 13, 13) */ /*[o] TODO */
}
