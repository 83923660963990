.theme-gold {
    --color-primary: rgb(255, 210, 158); /* #ffd29e;*/ /*[x] TODO */
    --color-secondary: rgb(255, 220, 168); /*#ffddaa;  */ /*[o] TODO */
    --color-accent: rgb(255, 210, 158); /*#ffd29e; */ /*[o] TODO */

    --color-action: rgb(255, 210, 158); /*#ffd29e; */ /*[o] TODO */
    --color-action-hover: rgb(255, 209, 123); /*#ffd17b;  */ /*[o] TODO */
    --color-focus: rgb(255, 210, 158); /*#ffd29e; */ /*[o] TODO */
    --color-disabled: rgb(153, 153, 153); /*#939393; */ /*[o] TODO */

    --color-border: rgb(129, 107, 81); /*#816b51; */ /*[x] TODO */
    --color-border-accent: rgb(118, 118, 118); /*#767676;  */ /*[x] TODO */

    --color-text: rgb(255, 255, 255); /*#ffffff; */ /*[o] TODO */
    --color-text-accent: rgb(255, 210, 158); /*#ffd29e; */ /*[x] TODO */

    --color-theme-white: rgb(255, 255, 255); /*#ffffff; */ /*[o] TODO */
    --color-theme-black: rgb(13, 13, 13); /*#0d0d0d; */ /*[o] TODO */
    --color-dark-black: #0d0d0d;
}

/* With Opacity */
.theme-gold {
    --color-primary-opacity: 255, 221, 170; /* #ffddaa; rgb(255,221,170) */ /*[o] TODO */
    --color-secondary-opacity: 129, 107, 81; /* #816b51; rgb(129,107,81)  */ /*[o] TODO  */
    --color-accent-opacity: 255, 210, 158; /* #ffd29e; rgb(255,210,158) */ /*[o] TODO */

    --color-action-opacity: 255, 210, 158; /*#ffd29e; rgb(255,210,158) */ /*[o] TODO */
    --color-action-hover-opacity: 255, 209, 123; /*#ffd17b; rgb(255,209,123) */ /*[o] TODO */
    --color-focus-opacity: 255, 210, 158; /*#ffd29e; rgb(255,210,158) */ /*[o] TODO */
    --color-disabled-opacity: 153, 153, 153; /*#939393; rgb(153,153,153) */ /*[o] TODO */

    --color-border-opacity: 129, 107, 81; /*#816b51; rgb(129,107,81)   */ /*[x] TODO */
    --color-border-accent-opacity: 118, 118, 118; /*#767676; rgb(118,118,118)  */ /*[x] TODO */

    --color-text-opacity: 217, 217, 217; /*#d9d9d9; rgb(217,217,217)  */ /*[o] TODO */
    --color-text-accent-opacity: 255, 210, 158; /*#ffd29e; rgb(255, 210, 158)   */ /*[x] TODO */

    --color-theme-white-opacity: 255, 255, 255; /*#ffffff; rgb(255,255,255)  */ /*[o] TODO */
    --color-theme-black-opacity: 13, 13, 13; /*#0d0d0d; rgb(13,13,13)     */ /*[o] TODO */
    --color-theme-opacity: 0.4;
}
